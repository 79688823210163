<template>
  <form
    class="s-controls flex items-center justify-center bg-teal text-white h-10 rounded-full px-1 transition-all ease-in-out duration-200 s-shadow font-main text-lg"
    :class="{
      'w-10': !opened,
      'w-full': opened,
      'w-24': peek
    }"
    @click="rootClick"
    @pointerenter="pointerEnter"
    @pointerleave="pointerLeave"
  >
    <Transition
      name="fade"
      mode="out-in"
    >
      <!-- COMPONENT CLOSED STATE / STATIC -->
      <div
        v-if="!opened && !peek"
        key="component-closed-key"
        style="transition-duration: 50ms"
      >
        <button
          type="button"
          class="w-10 h-10 flex items-center justify-center"
          @click="open"
        >
          <!-- COMPONENT CLOSED STATE / NO ITEMS IN CART-->
          <span v-if="!itemIsInCart">
            <IconPlus
              width="14"
              class="mt-0.5"
            />
          </span>

          <!-- COMPONENT CLOSED STATE / HAS ITEMS IN CART-->
          <span v-if="itemIsInCart">{{ qtyInCart }}</span>
        </button>
      </div>

      <!-- COMPONENT CLOSED STATE / PEEK -->
      <div
        v-else-if="peek"
        key="component-closed-peek-key"
        class="w-full peek"
        style="transition-duration: 50ms"
      >
        <button
          type="button"
          class="flex w-full items-center justify-between uppercase text-base"
          @click="closePeek"
        >
          <span class="pl-3">Add</span>
          <IconPlus
            width="14"
            class="mx-2 mt-0.5"
          />
        </button>
      </div>

      <!-- COMPONENT OPEN STATE / SELECT QTY -->
      <div
        v-else-if="opened && !submitting"
        key="select-qty-key"
        class="flex w-full items-center justify-between"
      >
        <button
          v-if="showIncDec"
          type="button"
          class="w-8 h-8 flex items-center justify-center bg-teal text-pink-50 rounded-full"
          @click="decrement"
        >
          <IconMinus
            width="14"
            class="mt-0.5"
          />
        </button>
        <button
          v-if="!showIncDec"
          class="w-8 h-8 flex items-center justify-center bg-pink-100 text-black rounded-full"
          type="button"
          @click="decrement"
        >
          <IconClose
            width="14"
            class="mt-0.5"
          />
        </button>
        <span>{{ adjustedQty }}</span>
        <button
          type="button"
          class="w-8 h-8 flex items-center justify-center bg-teal text-pink-50 rounded-full"
          @click="increment"
        >
          <IconPlus
            width="14"
            class="mt-0.5"
          />
        </button>
      </div>

      <!-- COMPONENT OPEN STATE / SUCCESS -->
      <div
        v-else-if="opened && submitting"
        key="success-qty-key"
        class="flex w-full items-center justify-center"
      >
        <IconCheckmarkBold width="24" />
      </div>
    </Transition>
  </form>
</template>

<script>
  import debounce from 'just-debounce-it'

  const SUBMIT_DELAY = 1600
  const CLOSE_DELAY = 1600
  const MAX_ADD = 99

  export default {
    props: {
      qtyInCart: {
        type: Number,
        required: true
      },
      productCardHover: {
        type: Boolean,
        required: true
      }
    },
    data() {
      return {
        adjustedQty: this.qtyInCart || 1,
        eagerlyAdjustedQty: -1,
        adjustmentPending: false,
        opened: false,
        submitting: false,
        peek: false,
        hover: false
      }
    },
    computed: {
      currentQty() {
        return this.eagerlyAdjustedQty >= 0 ? this.eagerlyAdjustedQty : this.qtyInCart
      },
      itemIsInCart() {
        return this.currentQty > 0
      },
      showIncDec() {
        return this.adjustedQty >= 2
      }
    },
    watch: {
      qtyInCart() {
        this.eagerlyAdjustedQty = -1
      },
      productCardHover(mouseEnter, mouseLeave) {
        if (mouseEnter) {
          this.handleMouseEnter()
        }
        if (mouseLeave) {
          this.handleMouseLeave()
        }
      },
      hover(mouseEnter) {
        if (mouseEnter) {
          this.handleMouseEnter()
        }
      }
    },
    created() {
      // @Note: These methods are created here because the debounce library
      // doesn't play nicely with the Vue options API. Mainly, the cancel fn
      // can't be accessed if the debounced fn is bound as a component method.
      const submitFn = debounce(() => {
        if (this.adjustedQty !== this.qtyInCart) {
          this.$emit('updateQty', this.adjustedQty)
          this.submitting = true
        }
        setTimeout(this.close, CLOSE_DELAY)
      }, SUBMIT_DELAY)

      this.submit = () => {
        this.eagerlyAdjustedQty = this.adjustedQty
        // @Note: An adjustment to qty zero will immediately quietly submit an update to the cart and
        // close/reset the controls. Any other adjustment will queue up the change, and close the
        // controls after the success animation.
        if (this.adjustedQty === 0) {
          if (this.adjustedQty !== this.qtyInCart) {
            this.$emit('updateQty', this.adjustedQty)
          }
          this.close()
        } else {
          this.adjustmentPending = true
          submitFn()
        }
      }

      this.cancelDebouncedSubmit = submitFn.cancel
    },
    methods: {
      rootClick(e) {
        e.preventDefault()
      },
      open() {
        this.opened = true
        this.submit()
      },
      closePeek() {
        this.peek = false
        this.open()
      },
      close() {
        this.cancelDebouncedSubmit()
        this.opened = false
        this.submitting = false
        this.adjustmentPending = false
        this.adjustedQty = this.currentQty || 1
      },
      increment() {
        if (this.adjustedQty < MAX_ADD) {
          this.adjustedQty++
          this.submit()
        }
      },
      decrement() {
        if (this.adjustedQty > 0) {
          this.adjustedQty--
          this.submit()
        }
      },
      pointerEnter(event) {
        if (event.pointerType === 'touch') return
        this.hover = true
      },
      pointerLeave(event) {
        if (event.pointerType === 'touch') return
        this.hover = false
      },
      handleMouseEnter() {
        if (this.currentQty === 0 && !this.adjustmentPending) {
          this.peek = true
        } else {
          this.opened = true
        }
      },
      handleMouseLeave() {
        if (!this.adjustmentPending) {
          this.opened = false
          this.peek = false
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .fade-enter-active,
  .fade-leave-active {
    transition-timing-function: ease-out;
    transition-property: opacity;
    transition-duration: 200ms;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .s-shadow {
    box-shadow: 1px 2px 14px 0 rgba(0, 0, 0, 0.13);
  }
  .s-controls {
    max-width: theme('space.36');
  }
</style>
