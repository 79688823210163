<template>
  <SalveImage
    class="s-productImage"
    width="180"
    loading="lazy"
    :url="imgData.path"
    :alt="alt"
  />
</template>

<script>
  import { imageData } from '@/utils/images'

  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      alt: {
        type: String,
        required: true
      },
      modifiers: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      imgData() {
        return imageData(this.url)
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-productImage img {
    @apply max-w-none;

    width: 100%;
    height: auto;

    @screen md {
      width: 180px;
    }

    @screen 4xl {
      width: 219px;
    }
  }
  .s-productImage--fixedHeight img {
    height: 280px;
    width: auto;
    @screen md {
      @apply h-auto;
    }
  }
</style>
